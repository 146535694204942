import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import RegisterCta from '../../signIn/registerCta';
import AuthForm from '../form';
import IdentifierInput from '../inputs/identifierInput';
import Layout from '../../pageLayout/layout';
import renderQueryString from '../../../../shared/urls/renderQueryString';
import { useStateContext } from '../../../modules/stateContext';
import {
    AUTH_PATH,
    FORGOTTEN_CREDENTIALS_PATH,
} from '../../../../shared/endpoints/account';
import { useShowExUkContent } from '../../../hooks/useShowExUkContent';
import useJsEnabled from '../../../hooks/useJsEnabled';
import EmailInput from '../inputs/emailInput';
import { HelpLinks } from '../helpLinks';

const Identifier = () => {
    const userContext = useStateContext();

    const {
        location: { isUk },
    } = userContext;

    const jsEnabled = useJsEnabled();

    const identifierAction = `${AUTH_PATH}${renderQueryString.call(
        { ...userContext, action: 'sign-in' },
        jsEnabled === false ? { jsEnabled: 'false' } : {}
    )}`;

    const suppressRegisterLinks = userContext.suppressRegisterLinks === 'true';

    const emailOnly = useShowExUkContent();

    const helpLinksItems = useMemo(
        () => [
            {
                href: FORGOTTEN_CREDENTIALS_PATH,
                label: emailOnly
                    ? 'cama.link.forgotEmail'
                    : 'cama.link.forgotCredential',
            },
        ],
        [emailOnly]
    );

    const heading = useMemo(() => {
        const title = (
            <FormattedMessage
                id={
                    emailOnly
                        ? 'auth.magicLink.entryPage.title'
                        : 'auth.identifier.title.intro'
                }
            />
        );

        if (isUk) {
            return title;
        }

        return (
            <>
                <div className="u-margin-bottom-quad">
                    <FormattedMessage id="auth.identifier.titleExUk" />
                </div>
                <div className="sb-heading--indexHeadlineLarge sb-heading--regular">
                    {title}
                </div>
            </>
        );
    }, [emailOnly, isUk]);

    return (
        <Layout
            pageId="signin-page"
            heading={heading}
            layoutContentContainerClassName={
                isUk ? undefined : 'u-margin-bottom-triple'
            }
            secondaryContent={!suppressRegisterLinks && <RegisterCta />}
            showServiceIdentifiers
        >
            <AuthForm
                action={identifierAction}
                buttonTranslationKey="button.continue.value"
            >
                {emailOnly ? <EmailInput /> : <IdentifierInput />}
            </AuthForm>

            <HelpLinks
                className="button__label u-margin-bottom-none"
                items={helpLinksItems}
            />
        </Layout>
    );
};

export default Identifier;
