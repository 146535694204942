import { values } from 'lodash';
import policies from './policies';
import ageBrackets from './ageBrackets';
import components from './components';

const ab = values(ageBrackets);
const policy = values(policies);
const component = values(components);

export { ab, policy, component };
