import styled from 'styled-components';

export const StyledIframeMagicLinkConfirmation = styled.div`
    display: flex;
    gap: var(--spacing-4);
    padding: 0 var(--spacing-1);
`;

export const StyledIframeMagicLinkConfirmationList = styled.ol`
    list-style: decimal;

    li {
        margin-left: var(--spacing-4);
    }
`;

export const StyledIframeMagicLinkConfirmationFormWrapper = styled.div`
    display: flex;
    flex-flow: column;
    gap: var(--spacing-4);

    .sb-field {
        padding: 0;

        &,
        .sb-form-message {
            margin: 0;
        }
    }
`;

export const StyledIframeMagicLinkConfirmationTextWrapper = styled.div`
    display: flex;
    flex-flow: column;
    gap: var(--spacing-4);
    line-height: var(--spacing-5);
`;

export const StyledIframeMagicLinkConfirmationLinks = styled.div`
    display: flex;
    flex-flow: column;
    gap: var(--spacing-4);
    justify-content: space-between;

    @media (min-width: 25rem) {
        flex-flow: row;
    }

    button {
        text-align: left;
    }
`;

export const StyledResendMagicLink = styled.div`
    position: relative;

    .sb-field {
        margin-bottom: 0;
        min-width: 9rem;

        @media (min-width: 25rem) {
            position: absolute;
        }
    }
`;
