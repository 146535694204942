import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@bbc-account/id-components';
import TextInput from '../textInput';
import FormError from '../formError';
import { errorShape, metaShape, inputShape } from '../../../propTypes';
import dateOfBirthFields from '../../../../shared/data/dateOfBirthFields';
import { isMonthDayYearCountry } from '../../../../shared/i18n/dateFormat';
import Explainer from '../explainer';
import FormattedMessageWithAttributes from '../formattedMessageWithAttributes';
import { useStateContext } from '../../../modules/stateContext';

const { DAY, MONTH, YEAR } = dateOfBirthFields;

const DOB_FIELDS = [DAY, MONTH, YEAR];

const DateOfBirthFields = props => {
    const {
        error,
        explainer,
        explainerHelpUrl,
        initialErrors,
        title,
        ...fieldProps
    } = props;

    const { location } = useStateContext();

    const areFieldsActive = DOB_FIELDS.some(field => props[field].meta.active);
    const allFieldsTouched = DOB_FIELDS.every(
        field => props[field].meta.touched
    );
    const showFormError = allFieldsTouched && !areFieldsActive;
    const showInitialError = !allFieldsTouched;
    const formError =
        (showFormError && error) ||
        (showInitialError && initialErrors.dateOfBirth);

    const sharedProps = {
        showValid: !areFieldsActive,
        required: true,
        hideErrorMessage: true,
    };

    const dayField = (
        <TextInput
            {...fieldProps[DAY]}
            {...sharedProps}
            autoComplete="bday-day"
            id="day-input"
            initialError={initialErrors[DAY]}
            pattern="[0-9]*"
            label={<FormattedMessage id="label.day" />}
            inputMode="numeric"
        />
    );

    const monthField = (
        <TextInput
            {...fieldProps[MONTH]}
            {...sharedProps}
            autoComplete="bday-month"
            id="month-input"
            initialError={initialErrors[MONTH]}
            pattern="[0-9]*"
            label={<FormattedMessage id="label.month" />}
            inputMode="numeric"
        />
    );

    const yearField = (
        <TextInput
            {...fieldProps[YEAR]}
            {...sharedProps}
            additionalFieldClasses="field--last"
            autoComplete="bday-year"
            id="year-input"
            initialError={initialErrors[YEAR]}
            pattern="[0-9]*"
            label={<FormattedMessage id="label.year" />}
            inputMode="numeric"
        />
    );

    const fields = isMonthDayYearCountry(location.country) ? (
        <>
            {monthField}
            {dayField}
            {yearField}
        </>
    ) : (
        <>
            {dayField}
            {monthField}
            {yearField}
        </>
    );

    return (
        <fieldset id="dateOfBirthField" className="fields fields--triplet">
            {title && (
                <legend className="u-padding-bottom-triple u-padding-top-quad">
                    <Heading
                        level={2}
                        fontScale="sectionHeading"
                        fontWeight="regular"
                    >
                        <FormattedMessage id={title} />
                    </Heading>
                </legend>
            )}

            {fields}

            <FormError name="dateOfBirth" error={formError} isAssertive />

            {explainer && (
                <Explainer
                    explainers={
                        <FormattedMessageWithAttributes
                            id={explainer}
                            attributes={{
                                href: explainerHelpUrl,
                                external: true,
                            }}
                        />
                    }
                    name="dateOfBirth"
                />
            )}
        </fieldset>
    );
};

DateOfBirthFields.displayName = 'DateOfBirthFields';

DateOfBirthFields.propTypes = {
    error: errorShape,
    explainer: PropTypes.string,
    explainerHelpUrl: PropTypes.string,
    initialErrors: PropTypes.shape({
        [DAY]: errorShape,
        [MONTH]: errorShape,
        [YEAR]: errorShape,
        dateOfBirth: errorShape,
    }).isRequired,
    [DAY]: PropTypes.shape({
        meta: metaShape.isRequired,
        input: inputShape.isRequired,
    }).isRequired,
    [MONTH]: PropTypes.shape({
        meta: metaShape.isRequired,
        input: inputShape.isRequired,
    }).isRequired,
    [YEAR]: PropTypes.shape({
        meta: metaShape.isRequired,
        input: inputShape.isRequired,
    }).isRequired,
    title: PropTypes.string,
};

export { DAY, MONTH, YEAR, DOB_FIELDS };

export default DateOfBirthFields;
