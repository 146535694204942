import React from 'react';
import AgeSelect from '../shared/pages/ageSelect';
import renderQueryString from '../../../shared/urls/renderQueryString';
import {
    REGISTER_CHILD_GUARDIAN_PATH,
    REGISTER_CHILD_GUARDIAN_FEDERATED_PATH,
    REGISTER_FEDERATED_DOB_PATH,
} from '../../../shared/endpoints/account';
import { AUTH_REGISTERV2 } from '../../../shared/endpoints/authV2';
import ageBrackets from '../../../shared/data/ageBrackets';
import { useConsentAge } from '../../context/consentAge';
import useJsEnabled from '../../hooks/useJsEnabled';
import { useStateContext } from '../../modules/stateContext';

function useAgePropsTranslationKey({
    translationKeys: { key, legacyExUkKey, legacyUkKey },
}) {
    const {
        featureToggles: { consentAgeByCountry },
        location: { isUk },
    } = useStateContext();

    const { consentAge } = useConsentAge();

    if (consentAgeByCountry) {
        return { id: key, values: { consentAge } };
    }

    if (isUk) {
        return { id: legacyUkKey };
    }

    return { id: legacyExUkKey };
}

function useAgeProps({
    additionalQueryStringParams,
    paths: { federated: federatedPath, nonFederated: nonFederatedPath },
    translationKeys,
}) {
    const userContext = useStateContext();

    const { isFederated } = userContext;

    const path = isFederated ? federatedPath : nonFederatedPath;

    const href = `${path}${renderQueryString.call(
        { ...userContext },
        additionalQueryStringParams
    )}`;

    const translationKey = useAgePropsTranslationKey({
        translationKeys,
    });

    return { href, translationKey };
}

function useUnderAgeProps() {
    return useAgeProps({
        paths: {
            federated: REGISTER_CHILD_GUARDIAN_FEDERATED_PATH,
            nonFederated: REGISTER_CHILD_GUARDIAN_PATH,
        },
        translationKeys: {
            key: 'common.button.underAgeBoundary.consentAge.value',
            legacyExUkKey: 'common.button.underAgeBoundary.international.value',
            legacyUkKey: 'common.button.underAgeBoundary.uk.value',
        },
    });
}

function useOverAgeProps() {
    const isJsEnabled = useJsEnabled();

    const { isFederated } = useStateContext();

    const additionalQueryStringParams = {};

    if (isJsEnabled === false) {
        additionalQueryStringParams.jsEnabled = 'false';
    }

    if (!isFederated) {
        additionalQueryStringParams.ab = ageBrackets.OVER_13;
    }

    return useAgeProps({
        additionalQueryStringParams,
        paths: {
            federated: REGISTER_FEDERATED_DOB_PATH,
            nonFederated: AUTH_REGISTERV2,
        },
        translationKeys: {
            key: 'common.button.overAgeBoundary.consentAge.value',
            legacyExUkKey: 'common.button.overAgeBoundary.international.value',
            legacyUkKey: 'common.button.overAgeBoundary.uk.value',
        },
    });
}

const RegisterAgeSelect = () => {
    const underAgeProps = useUnderAgeProps();

    const overAgeProps = useOverAgeProps();

    return (
        <AgeSelect
            underAgeBoundary={underAgeProps}
            overAgeBoundary={overAgeProps}
        />
    );
};

RegisterAgeSelect.displayName = 'RegisterAgeSelect';

export default RegisterAgeSelect;
